// Styles

// Main styles

@import "./app-modern.scss";

// Hyper Icons
@import "./icons.scss";

.modal-90w {
  max-width: none !important;
  width: 75%;
}

.bg-modal {
  background-color: $body-bg;
}
