//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
  + .breadcrumb-item {
    &::before {
      font-family: "Material Design Icons";
      font-size: 1.8rem;
      line-height: 1;
    }
  }
}

.breadcrumb {
  margin-bottom: 0 !important;
}
