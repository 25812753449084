//
// Ubuntu fonts
//

@font-face {
  font-family: "OpenSans";
  src: url("../../../fonts/OpenSans-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../../../fonts/OpenSans-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../../../fonts/OpenSans-Medium.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../../../fonts/OpenSans-SemiBold.ttf");
  font-weight: 700;
  font-style: normal;
}
